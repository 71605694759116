import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`Summer has gone by so fast. And within just a few days, the kids are back at school, and the morning traffic resumes to its busy normal.`}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3lgB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFhABAQEAAAAAAAAAAAAAAAAAARAg/9oACAEBAAE/ISOP/9oADAMBAAIAAwAAABBQz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAAICAwAAAAAAAAAAAAAAAAERACEQMaH/2gAIAQEAAT8QBNIO4L2eRAaKyp//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Stock Market Indicators",
            "title": "Stock Market Indicators",
            "src": "/static/22ff4c47d424ac7ff9485f451b6e7222/e5166/indicators-stocks.jpg",
            "srcSet": ["/static/22ff4c47d424ac7ff9485f451b6e7222/f93b5/indicators-stocks.jpg 300w", "/static/22ff4c47d424ac7ff9485f451b6e7222/b4294/indicators-stocks.jpg 600w", "/static/22ff4c47d424ac7ff9485f451b6e7222/e5166/indicators-stocks.jpg 1200w", "/static/22ff4c47d424ac7ff9485f451b6e7222/eea4a/indicators-stocks.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`August has been a rollercoaster for investors. The first half of the month, the stock market was pointing mostly downward, with investors scared by a combination of recession fears and the aftermath of `}<a parentName="p" {...{
        "href": "https://www.npr.org/2023/08/02/1178215888/fitch-downgrade-credit-rating-deficits-debt-economy"
      }}>{`Fitch’s US debt-rating downgrade`}</a>{`. But, like the last downgrade in 2011, the trend reversed quickly in the second half of the month. Market volatility dwindled away, and neither the 30-day volatility, nor the 12-month volatility indicate a meaningful level of market fear. We are now in striking distance of the December 2021 highs, and if this trend continues, the S&P might be making new all-time highs soon.`}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3qgB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFhAAAwAAAAAAAAAAAAAAAAAAASBx/9oACAEBAAE/IRG//9oADAMBAAIAAwAAABATz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQABBAMAAAAAAAAAAAAAAAEAEBEhMUFxkf/aAAgBAQABPxCwunKCrqLnJNUTv2f/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Market Vane: Monthly Indicators",
            "title": "Market Vane: Monthly Indicators",
            "src": "/static/ef5e711f81136d9692f88f453e4c999e/e5166/market-vane-monthly.jpg",
            "srcSet": ["/static/ef5e711f81136d9692f88f453e4c999e/f93b5/market-vane-monthly.jpg 300w", "/static/ef5e711f81136d9692f88f453e4c999e/b4294/market-vane-monthly.jpg 600w", "/static/ef5e711f81136d9692f88f453e4c999e/e5166/market-vane-monthly.jpg 1200w", "/static/ef5e711f81136d9692f88f453e4c999e/eea4a/market-vane-monthly.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Given this recovery, it would have been a pity had `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane`}</a>{` exited at the end of August, potentially missing out on the gains ahead. But, on August 30, it still looked like this was happening, due to negative stock market momentum. Luckily, Market Vane recognized the reversal in direction, and reduced its lookback period, allowing the indicator to track the markets more closely. With this adjustment, the stock market momentum is now back to solid green. Also, stocks continue to have a strong edge over bonds. With two of the monthly indicators positive, Market Vane should remain bullish.`}</p>
    <p><a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/CCSA"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1138px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIklEQVQoz41R21KDQAzl/3/GF1/8Bp+cscWilALlJpeWwrJ3jrOBah8cbWbOZJM9ySZnvYfHJzy/bJFUHYJDhve4IB+mFbLmgqzuF/8nFk5a9/CiokNSthDK4NZmANZaWDv/A0twFSOX8IKkQlw0EEpDKk0XjjDP813A1QNLQz/KkZbtd0Ibu454XyOzPu6McQVvF5dIq44SxlgMkwQXClxqWum3Zo4nlcHkeCtXSI3zwJeVk6K5Ue6nyJG0NhRf9ZSrNLTJOtmi4bryx7GmCUcuKDFOAgPjdL4wjp5J1KcRp0GgOTNcmMTgOASJfpzAhKK6bpjg7ZIam6jC676Ef/gkuJ8PswZvUY4gKeHvMwRxiW14hJNon7eEhddSbhOmFH8B26JoWE50KioAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "FRED Continued Unemployment Claims",
            "title": "FRED Continued Unemployment Claims",
            "src": "/static/817b4655d5c06118b63730a7707a782b/05fb0/fred-CCSA.png",
            "srcSet": ["/static/817b4655d5c06118b63730a7707a782b/5a46d/fred-CCSA.png 300w", "/static/817b4655d5c06118b63730a7707a782b/0a47e/fred-CCSA.png 600w", "/static/817b4655d5c06118b63730a7707a782b/05fb0/fred-CCSA.png 1138w"],
            "sizes": "(max-width: 1138px) 100vw, 1138px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`But Market Vane’s third monthly indicator, the economic momentum, is bearish and has been for almost a year. Curious readers might wonder why that is. Internally, Market Vane uses `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/CCSA"
      }}>{`Continued Unemployment Claims`}</a>{` to gage the economy, and since September 2022, these figures have been rising. There is no reason to believe that this trend will reverse any time soon, given that unemployment is still hovering near historic lows.`}</p>
    <p><a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/CUSR0000SAS"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1138px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVQoz5WQzW6DMBCE/f6P00NPfYge2rQpCRAIPwkEiAHb+G8q20kqJT20lj4N3kG7syZPzy94XUVIqxOiXYloV2GTVYgypzW2+QFfaYms7lCeRpQt9eTNGfnxjKKlN1yN1AODsfjTWaQGX9QD2hjvT3wBScsWxhhYax/ABak0mJBe3b++gQ0ptDa+qTEWs5AgSdHA3DdCUKU1uJBYLo2u9fuhTl36YeIgiU94Z6qwmritY3/d4Iq5pB3ZApId+lt8pY2fdH2T/x4XguTHAcMk0FGGns6YuQSdmJ9GZ46RiVCbOZhQOI8/Hp14+J6C1/QjyDpv8RYf8J44arj7tmiRVD2i/IjPtMJ6V2MV771u9o334vLkSaoOH0nhcd436g5qW+E7hjcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "FRED Consumer Price Index, Services",
            "title": "FRED Consumer Price Index, Services",
            "src": "/static/a80de8cc5edf6b23bdc278b9ef54024c/05fb0/fred-CUSR0000SAS.png",
            "srcSet": ["/static/a80de8cc5edf6b23bdc278b9ef54024c/5a46d/fred-CUSR0000SAS.png 300w", "/static/a80de8cc5edf6b23bdc278b9ef54024c/0a47e/fred-CUSR0000SAS.png 600w", "/static/a80de8cc5edf6b23bdc278b9ef54024c/05fb0/fred-CUSR0000SAS.png 1138w"],
            "sizes": "(max-width: 1138px) 100vw, 1138px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`This low unemployment is likely to blame for the stubbornly high inflation in services, which exceeds the topline inflation rate. As shown by the `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/CUSR0000SAS"
      }}>{`Consumer Price Index for Services`}</a>{`, services see a year-over-year increase of over 5.7%. This likely won’t come down unless unemployment rises further.`}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3aEUf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAQACAwAAAAAAAAAAAAAAAAEAEBEhgf/aAAgBAQABPyEAmp2sV//aAAwDAQACAAMAAAAQ88//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMVEQ/9oACAEBAAE/EKDu3d+Hl4Yi4JOEP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bond Market Indicators",
            "title": "Bond Market Indicators",
            "src": "/static/9ef5b2ac7b6ba514fd5c551c325caa5d/e5166/indicators-bonds.jpg",
            "srcSet": ["/static/9ef5b2ac7b6ba514fd5c551c325caa5d/f93b5/indicators-bonds.jpg 300w", "/static/9ef5b2ac7b6ba514fd5c551c325caa5d/b4294/indicators-bonds.jpg 600w", "/static/9ef5b2ac7b6ba514fd5c551c325caa5d/e5166/indicators-bonds.jpg 1200w", "/static/9ef5b2ac7b6ba514fd5c551c325caa5d/eea4a/indicators-bonds.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Consequently, the Fed still has more work to do, and will probably continue to hike rates. The next hike is already priced in: From the spread between the `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/GS3M"
      }}>{`13-week market yield`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/DFF"
      }}>{`Federal Funds Rate`}</a>{`, we can see that the market fully expects another 25bps increase. Consequently, the markets should react rather calmly, if the Fed decides another rate hike during the `}<a parentName="p" {...{
        "href": "https://www.federalreserve.gov/monetarypolicy/fomccalendars.htm"
      }}>{`FOMC Meeting on September 20`}</a>{`.`}</p>
    <p>{`With this backdrop, we hope that investing through the month of September will be rather uneventful – which, when it comes to investing, is always a good thing.`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      